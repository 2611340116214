@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,800;1,400;1,600;1,700&display=swap');

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/* header */

.headerMain {
    position: sticky;
    top: 0px;
    box-shadow: 0px 5px 15px #3880FF1A;
    z-index: 999;
    background-image: url('../header_back.png');
    background-size: cover;
    padding: 15px 0;
}

.headerDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logoImage img {
    height: 90px;
}

.header_Btns {
    display: flex;
    gap: 20px;
}

.headerBtnWidth {
    width: 135px;
    height: 37px;
}
.buttonBuy{
    width: 100px;
    height:36px;
}

.paddingLeft{
    padding-left: 100px;
}
.fw500{
    font-weight: 500;
}

/* section 1 */
.loaderDiv {
    display: grid;
    place-items: center;
    min-height: 390px;

}

.light_background {
    /* height: 800px; */
    position: relative;
    background-image: url('../images/bgWithPankh.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.pankhImage {
    position: absolute;
    content: '';
    left: 0px;
    right: 0px;
    top: 0px;
    height: 100%;
    background-image: url('../images/sidePankh.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.firstMain {
    display: flex;
    gap: 30px;
    padding: 50px 0px;
}

.squareImageDiv {
    height: 490px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 5px 20px #0000001A;
    border-radius: 17px;
    opacity: 1;
    overflow-y: scroll;
    padding-inline: 1rem;
}

.cardComp>.slick-slide.slick-active.slick-current{
    width:550px !important;
}

.ImageCard {
    background-color: #edf3ff;
    padding: 8px 8px;
    border-radius: 17;
    width: 22.5rem;
    height: 22.5rem;
}

.ImageCardCrousel {
    background-color: #edf3ff;
    padding: 8px 18px;
    border-radius: 17px;
    width: 95%;
    text-align: center;
}

.carousel-item {
    margin-right: 10px;
}

.image-wrapper {
    padding: 0 5px;
}

.ImageCard img {
    /* max-height: 153px; */
    border-radius: 10px;
    min-height: 22.5rem;
    height: 22.5rem;
}

.imageCardText {
    text-align: center;
    font-size: 0.9rem;
    font-weight: 500;
    display: -webkit-inline-box;
    word-break: break-all;
    overflow: scroll;
    max-height: 54px;
}

/* secton4 other video */
.orangeText {
    font-weight: 600;
    color: #FE6A00;
    font-size: 2.5rem;
}

.orangeTextOnly{
    color: #FE6A00;
}

.blackText {
    font-weight: 500;
    font-size: 2.5rem;
}

/* section6 contact */
.contactMain {
    padding-bottom: 30px;
    display: flex;
    gap: 80px;
}

.paddingWalaDiv {
    padding-left: 40px;
}

.formMainDiv input {
    background-color: #edf3ff;
    outline: none !important;
    border: none;
    padding: 15px 0px 15px 12px;
    margin-top: 10px;
}

.formMainDiv ::placeholder {
    color: black !important;
    font-weight: 400;
}

.contatcSendBtn {
    background-color: #FE6A00;
    outline: none;
    color: white;
    border: none;
    margin-top: 6px;
}

.watchBtn{
    background-color: #FE6A00;
    outline: none;
    color: white;
    border: none;
    height:60px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    padding: 0 3rem;
}

.contatcSendBtn:hover {
    color: white !important;
}

.messageField {
    resize: none !important;
    background-color: #edf3ff;
    border: none;
    margin-top: 10px;
}

/* footer */
.footerLogo {
    /* background-color: #b3c5e2; */
    background-image: url("../images/footerBackImg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerLogo img {
    height: 130px;
}

.footer_LastPatti {
    background-color: #FE6A00;
    text-align: center;
    min-height: 42px;
    color: white;
    padding-top: 12px;
}

.footer_LastPatti img {
    width: 5%;
}