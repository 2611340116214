@media screen and (max-width:991px) {
    .marginForResponsive{
        margin-top: 30px;
    }
    .textMainDiv {
        padding-left: 50px;
        text-align: center;
    }
    .downloadBtns {
        text-align: center;
    }
    .footer_LastPatti img{
        width: 10%;
    }
    .paddingWalaDiv{
        padding-left: 0px;
    }
    .playBtn{
        top: 34% !important;
        height: 52px !important;
        left: 40% !important;    
    }
    .pauseBtn{
        top: 34% !important;
        height: 52px;
        left: 40%;
    }
    .videoListBack video {
        height: 95px !important;
    }
}